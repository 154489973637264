"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _mjmlCore = _interopRequireWildcard(require("mjml-core"));

var _mjmlValidator = require("mjml-validator");

var _mjmlSocial = require("mjml-social");

var _mjmlNavbar = require("mjml-navbar");

var _mjmlCarousel = require("mjml-carousel");

var _mjmlAccordion = require("mjml-accordion");

var _mjmlBody = _interopRequireDefault(require("mjml-body"));

var _mjmlHead = _interopRequireDefault(require("mjml-head"));

var _mjmlHeadAttributes = _interopRequireDefault(require("mjml-head-attributes"));

var _mjmlHeadBreakpoint = _interopRequireDefault(require("mjml-head-breakpoint"));

var _mjmlHeadHtmlAttributes = _interopRequireDefault(require("mjml-head-html-attributes"));

var _mjmlHeadFont = _interopRequireDefault(require("mjml-head-font"));

var _mjmlHeadPreview = _interopRequireDefault(require("mjml-head-preview"));

var _mjmlHeadStyle = _interopRequireDefault(require("mjml-head-style"));

var _mjmlHeadTitle = _interopRequireDefault(require("mjml-head-title"));

var _mjmlHero = _interopRequireDefault(require("mjml-hero"));

var _mjmlButton = _interopRequireDefault(require("mjml-button"));

var _mjmlColumn = _interopRequireDefault(require("mjml-column"));

var _mjmlDivider = _interopRequireDefault(require("mjml-divider"));

var _mjmlGroup = _interopRequireDefault(require("mjml-group"));

var _mjmlImage = _interopRequireDefault(require("mjml-image"));

var _mjmlRaw = _interopRequireDefault(require("mjml-raw"));

var _mjmlSection = _interopRequireDefault(require("mjml-section"));

var _mjmlSpacer = _interopRequireDefault(require("mjml-spacer"));

var _mjmlText = _interopRequireDefault(require("mjml-text"));

var _mjmlTable = _interopRequireDefault(require("mjml-table"));

var _mjmlWrapper = _interopRequireDefault(require("mjml-wrapper"));

var _dependencies = _interopRequireDefault(require("./dependencies"));

(0, _mjmlCore.registerComponent)(_mjmlBody["default"]);
(0, _mjmlCore.registerComponent)(_mjmlHead["default"]);
(0, _mjmlCore.registerComponent)(_mjmlHeadAttributes["default"]);
(0, _mjmlCore.registerComponent)(_mjmlHeadBreakpoint["default"]);
(0, _mjmlCore.registerComponent)(_mjmlHeadHtmlAttributes["default"]);
(0, _mjmlCore.registerComponent)(_mjmlHeadFont["default"]);
(0, _mjmlCore.registerComponent)(_mjmlHeadPreview["default"]);
(0, _mjmlCore.registerComponent)(_mjmlHeadStyle["default"]);
(0, _mjmlCore.registerComponent)(_mjmlHeadTitle["default"]);
(0, _mjmlCore.registerComponent)(_mjmlHero["default"]);
(0, _mjmlCore.registerComponent)(_mjmlButton["default"]);
(0, _mjmlCore.registerComponent)(_mjmlColumn["default"]);
(0, _mjmlCore.registerComponent)(_mjmlDivider["default"]);
(0, _mjmlCore.registerComponent)(_mjmlGroup["default"]);
(0, _mjmlCore.registerComponent)(_mjmlImage["default"]);
(0, _mjmlCore.registerComponent)(_mjmlRaw["default"]);
(0, _mjmlCore.registerComponent)(_mjmlSection["default"]);
(0, _mjmlCore.registerComponent)(_mjmlSpacer["default"]);
(0, _mjmlCore.registerComponent)(_mjmlText["default"]);
(0, _mjmlCore.registerComponent)(_mjmlTable["default"]);
(0, _mjmlCore.registerComponent)(_mjmlWrapper["default"]);
(0, _mjmlCore.registerComponent)(_mjmlSocial.Social);
(0, _mjmlCore.registerComponent)(_mjmlSocial.SocialElement);
(0, _mjmlCore.registerComponent)(_mjmlNavbar.Navbar);
(0, _mjmlCore.registerComponent)(_mjmlNavbar.NavbarLink);
(0, _mjmlCore.registerComponent)(_mjmlAccordion.Accordion);
(0, _mjmlCore.registerComponent)(_mjmlAccordion.AccordionElement);
(0, _mjmlCore.registerComponent)(_mjmlAccordion.AccordionText);
(0, _mjmlCore.registerComponent)(_mjmlAccordion.AccordionTitle);
(0, _mjmlCore.registerComponent)(_mjmlCarousel.Carousel);
(0, _mjmlCore.registerComponent)(_mjmlCarousel.CarouselImage);
(0, _mjmlValidator.registerDependencies)(_dependencies["default"]);
var _default = _mjmlCore["default"];
exports["default"] = _default;
module.exports = exports.default;