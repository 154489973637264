/**
 * This bundle contains all 3rd party dependencies used by the React Components.
 * Please refer to https://w.amazon.com/index.php/Goodreads/Engineering/Web/Front_End_Developer_Guide#Build_Output
 * for more details.
 */

/* eslint-disable */
import "object-assign";
import "lodash";
import "moment";
import "classnames";
import "freezer-js";
import "reflux";
import React from "react";
import ReactDOM from "react-dom";
import "react-addons-pure-render-mixin";
import "react-addons-linked-state-mixin";
import "redux";
import "react-redux";
import "redux-logger";
import "react-router";
import "redux-thunk";
import "react-lazysizes";
import "history";
import "headroom.js";
/* react-onclickoutside is a deprecated package that should not be used for new
   code. Instead of having an outside click close a module, there should be an
   explicit close affordances in the spec. */
import "react-onclickoutside";
import "diff";
import "uuid";

window.React = React;
window.ReactDOM = ReactDOM;
/* eslint-enable */
